import constants from "../../data/constants.json";

const { paypalEmail } = constants;

export const validateOrderPlaced = () => {
    const orderData = window.localStorage.getItem("orderData");
    const storedOrderData = orderData ? JSON.parse(orderData) : null;

    if (!storedOrderData) {
        return { valid: false, error: "Payment was cancelled or invalid." };
    }
    const urlParams = new URLSearchParams(window.location.search);
    if (
        urlParams.get("business") === paypalEmail &&
        urlParams.get("invoice") === String(storedOrderData.invoice) &&
        urlParams.get("amount") === String(storedOrderData.amount) &&
        urlParams.get("currency_code") === storedOrderData.currency_code
    ) {
        // if the url param canceled exists, then the user cancelled the payment
        if (urlParams.has("cancelled")) {
            return { valid: false, error: "Payment was cancelled or invalid." };
        }
        return { valid: true, error: null };
    } else {
        return { valid: false, error: "Payment was cancelled or invalid." };
    }
    // window.localStorage.removeItem("orderData");
};
