import landingPageData from "../data/landingPage.json";

function LandingPage() {
    return (
        <section className="LandingPage container black-bg">
            <h2 className="mb-3">{landingPageData.title}</h2>

            <p className="mb-4">{landingPageData.intro}</p>

            <div className="row">
                <div className="col-lg-6">
                    <h3 className="mt-4 mb-3">Our Comprehensive Writing & Rewriting Services:</h3>
                    <ol className="mb-4">
                        {landingPageData.services.map((service, index) => (
                            <li key={index}>
                                <strong>{service.title}</strong>: {service.description}
                            </li>
                        ))}
                    </ol>
                </div>

                <div className="col-lg-6">
                    <h3 className="mt-4 mb-3">Editing Expertise:</h3>
                    <ul className="mb-4">
                        {landingPageData.editing.map((edit, index) => (
                            <li key={index}>
                                <strong>{edit.title}</strong>: {edit.description}
                            </li>
                        ))}
                    </ul>
                    <h3 className="mt-4 mb-3">Service Assurance & Pricing:</h3>
                    <ul className="mb-4">
                        {landingPageData.assuranceAndPricing.map((item, index) => (
                            <li key={index}>
                                <strong>{item.title}</strong>: {item.description}
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <p>{landingPageData.conclusion}</p>
            <p>{landingPageData.outro}</p>
        </section>
    );
}

export default LandingPage;
