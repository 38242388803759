import React, { useRef } from "react";
import { handleFormSubmit } from "./handleFormSubmit";
import { toDataUrl } from "./toDataUrl";

export const OrderForm = ({ formValues, setFormValues, setShowToast, setToastOptions }) => {
    const formRef = useRef(null);

    const handleFileChange = async (e, fieldId) => {
        const file = e.target.files[0];
        const dataUrl = await toDataUrl(file);
        setFormValues({ ...formValues, [fieldId]: dataUrl });
    };

    const submitForm = async (e) => {
        e.preventDefault();
        try {
            setToastOptions({
                bodyText: "Submitting your order...",
                options: {
                    theme: "info",
                    showClose: false,
                    overlay: true,
                },
            });
            setShowToast(true);

            const paypalUrl = await handleFormSubmit(e, formRef);

            setToastOptions({
                bodyText: "Your order has been submitted. You will be redirected to PayPal to complete the payment.",
                options: {
                    theme: "success",
                    showClose: false,
                    overlay: true,
                    okButton: {
                        text: "Close",
                        onClick: () => {
                            window.location.href = paypalUrl;
                        },
                    },
                },
            });
            setShowToast(true);
        } catch (err) {
            console.error(err);
            setToastOptions({
                bodyText: "There was an error submitting your order. Please try again, or contact us for assistance.",
                options: {
                    theme: "error",
                    showClose: false,
                    overlay: true,
                    okButton: {
                        text: "Close",
                    },
                },
            });
            setShowToast(true);
        }
    };

    return (
        <form onSubmit={submitForm} ref={formRef}>
            <div className="form-group">
                <label htmlFor="email">* Email Address:</label>
                <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    required
                    value={formValues.email ?? ""}
                    onChange={(e) => setFormValues({ ...formValues, email: e.target.value })}
                />
            </div>

            <div className="form-group">
                <label htmlFor="service">* Select a Service:</label>
                <select
                    className="form-control"
                    id="service"
                    name="service"
                    required
                    defaultValue={formValues.service ?? ""}
                    onChange={(e) => setFormValues({ ...formValues, service: e.target.value })}
                >
                    <option value="" disabled>
                        Select a Service
                    </option>
                    <option value="adCopy">Ad Copy Perfection - $15</option>
                    <option value="careerDocs">Career Documents - $25</option>
                    <option value="socialMedia">Social Media Excellence - $10</option>
                    <option value="productNarrative">Product Narratives - $15</option>
                    <option value="customDocs">Custom Document Drafting - $25</option>
                    <option value="other">Other - $15</option>
                </select>
            </div>

            <div className="form-group">
                <label htmlFor="instructions">* Instructions/Message:</label>
                <textarea
                    className="form-control"
                    id="instructions"
                    name="instructions"
                    rows="4"
                    required
                    defaultValue={formValues.instructions ?? ""}
                    onChange={(e) => setFormValues({ ...formValues, instructions: e.target.value })}
                ></textarea>
            </div>

            <div className="form-group">
                <label htmlFor="content">Source Content: (optional, or attach a file below)</label>
                <textarea
                    className="form-control"
                    id="content"
                    name="content"
                    rows="4"
                    defaultValue={formValues.content ?? ""}
                    onChange={(e) => setFormValues({ ...formValues, content: e.target.value })}
                ></textarea>
            </div>

            <div className="form-group">
                <label htmlFor="fileUpload">Upload a Document:</label>
                <input
                    type="file"
                    className="form-control-file"
                    id="fileUpload"
                    name="fileUpload"
                    accept=".pdf,.txt,.rtf,.doc,.docx"
                    onChange={(e) => handleFileChange(e, "fileUpload")}
                />
            </div>

            <div className="form-group form-check">
                <input
                    type="checkbox"
                    className="form-check-input"
                    id="rush"
                    name="rush"
                    checked={formValues.rush ?? false}
                    onChange={(e) => setFormValues({ ...formValues, rush: e.target.checked })}
                />
                <label className="form-check-label" htmlFor="rush">
                    Rush Service (Additional $50 for 1 business day turnaround)
                </label>
            </div>

            <div className="form-group" style={{ display: "none" }}>
                <label htmlFor="agree">Agree to leave this empty?</label>
                <input
                    type="text"
                    className="form-control"
                    id="agree"
                    name="agree"
                    value={formValues.agree ?? ""}
                    onChange={(e) => setFormValues({ ...formValues, agree: e.target.value })}
                />
            </div>

            <button type="submit" className="btn btn-primary">
                Submit
            </button>
        </form>
    );
};
