import constants from "../../data/constants.json";

const { workerEndpoint, paypalUrl, paypalEmail } = constants;

export const buildPayPalURL = ({ itemName, amount, invoice }) => {
    if (!amount || !itemName || !invoice) {
        throw new Error("Missing required parameters");
    }

    const url = new URL(window.location.href);
    url.searchParams.set("cancelled", "true");
    const orderData = {
        business: paypalEmail,
        item_name: itemName,
        amount: amount,
        currency_code: "USD",
        return: window.location.href,
        cancel_return: url.href,
        notify_url: `${workerEndpoint}/paypal/record-ipn/`,
        invoice,
    };

    window.localStorage.setItem("orderData", JSON.stringify(orderData));

    const params = new URLSearchParams(orderData).toString();
    return `${paypalUrl}&${params}`;
};
