import axios from "axios";
import { buildPayPalURL } from "./buildPayPalURL";
import { toDataUrl } from "./toDataUrl";

const recordOrder = async (orderData) => {
    const result = await axios.post("https://worker.anml.dev/wordcraft-studios/order", orderData, {
        headers: {
            "Content-Type": "application/json",
            "x-anml-auth": "wordcraft-studios-order",
        },
    });

    return result;
};

export const handleFormSubmit = async (e, formRef) => {
    e.preventDefault();
    if (!e.target.checkValidity()) {
        e.target.reportValidity();
        return;
    }

    const service = formRef.current.querySelector("#service").value;
    const formDataLocalStorage = window.localStorage.getItem("formValues");
    const storedformData = formDataLocalStorage ? JSON.parse(formDataLocalStorage) : null;
    const invoice_id = storedformData.invoice ?? new Date().getTime();
    const status = "pending";
    const rush = formRef.current.querySelector("#rush").checked;
    const email = formRef.current.querySelector("#email").value;
    const instructions = formRef.current.querySelector("#instructions").value;
    const content_file = formRef.current.querySelector("#fileUpload").files?.[0] ?? null;
    const content_text = formRef.current.querySelector("#content").value;

    let servicePrice = parseFloat(formRef.current.querySelector("#service").selectedOptions[0].innerText.split("$")[1]);
    if (rush) {
        servicePrice += 50;
    }
    servicePrice = servicePrice.toFixed(2);

    const orderData = {
        invoice_id,
        status,
        email,
        service,
        instructions,
        content_file: {
            filename: content_file?.name ?? null,
            data: content_file ? await toDataUrl(content_file) : null,
        },
        content_text,
    };
    const orderRecorded = await recordOrder(orderData);
    if (!orderRecorded?.status === 200) {
        throw new Error("Order could not be created.");
    }

    return buildPayPalURL({ itemName: service, amount: servicePrice, invoice: invoice_id });
};
