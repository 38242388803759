import React, { useCallback, useEffect, useState } from "react";
import Accordion from "../../components/Accordion";
import faqs from "../../data/faqs.json";
import orderForm from "../../data/orderForm.json";
import { OrderForm } from "./OrderForm";
import { validateOrderPlaced } from "./validateOrderPlaced";

function Order({ setShowToast, setToastOptions }) {
    const [formValues, setFormValuesOriginal] = useState(() => {
        const storedValues = window.localStorage.getItem("formValues");
        return typeof storedValues === "string" ? JSON.parse(storedValues) : {};
    });

    const showCancelToast = useCallback(
        (urlParams) => {
            setToastOptions({
                bodyText: "Payment was cancelled or invalid.",
                onClose: () => {
                    urlParams.delete("cancelled");
                    const newSearch = urlParams.toString();
                    window.history.replaceState({}, "", `${window.location.pathname}${newSearch ? "?" + newSearch : ""}`);
                },
                options: {
                    theme: "error",
                    showClose: false,
                    overlay: true,
                    cancelButton: {
                        text: "Close",
                    },
                },
            });
            setShowToast(true);
        },
        [setShowToast, setToastOptions]
    );

    const showSuccessToast = useCallback(
        (urlParams, orderPlaced) => {
            let theme = orderPlaced.valid ? "success" : "error";
            let bodyText = orderPlaced.valid
                ? "Thanks! We will reach out to you within 1 business day to confirm the order."
                : orderPlaced.error;

            setToastOptions({
                bodyText: bodyText,
                onClose: () => {
                    urlParams.delete("cancelled");
                    const newSearch = urlParams.toString();
                    window.history.replaceState({}, "", `${window.location.pathname}${newSearch ? "?" + newSearch : ""}`);
                },
                options: {
                    theme: theme,
                    showClose: false,
                    overlay: true,
                    cancelButton: {
                        text: "Close",
                    },
                },
            });
            setShowToast(true);
        },
        [setShowToast, setToastOptions]
    );

    useEffect(() => {
        if (window?.orderChecked) return;
        window.orderChecked = true;
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has("cancelled")) {
            showCancelToast(urlParams);
        } else if (urlParams.has("invoice") && urlParams.has("amount") && urlParams.has("currency_code")) {
            const orderPlaced = validateOrderPlaced();
            showSuccessToast(urlParams, orderPlaced);
        }
    }, [showCancelToast, showSuccessToast]);

    const setFormValues = (newFormValues) => {
        if (!newFormValues) return;
        if (JSON.stringify(newFormValues) === "{}") return;
        window.localStorage.setItem("formValues", JSON.stringify(newFormValues));
        setFormValuesOriginal(newFormValues);
    };

    return (
        <section className="OrderForm container black-bg">
            <div className="row">
                <div className="col-lg-6" id="orderWrapper">
                    <h3>Order Form:</h3>
                    <p>{orderForm.intro}</p>
                    <OrderForm
                        formValues={formValues}
                        setFormValues={setFormValues}
                        setShowToast={setShowToast}
                        setToastOptions={setToastOptions}
                    />
                </div>
                <div className="col-lg-6" id="faqWrapper">
                    <h3>FAQ:</h3>
                    <Accordion content={faqs} />
                </div>
            </div>
        </section>
    );
}

export default Order;
