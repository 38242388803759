import React, { useEffect, useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import "./assets/responsive.css";
import "./assets/style.css";
import Header from "./components/Header.js";
import LandingPage from "./components/LandingPage.js";
import Samples from "./components/Samples.js";
import samples from "./data/samples.json";
import Contact from "./pages/contact";
import Order from "./pages/order";

import "./assets/Toast.css";
import Toast from "./components/Toast";

export const pages = {
    "/": {
        label: "Home",
        subItems: {},
        component: LandingPage,
    },
    "/order": {
        label: "Order",
        subItems: {},
        component: Order,
    },
    "/samples": {
        label: "Samples",
        subItems: samples,
        component: Samples,
    },
    "/contact": {
        label: "Contact",
        subItems: {},
        component: Contact,
    },
};

function App() {
    const [showToast, setShowToast] = useState(false);
    const [toastOptions, setToastOptions] = useState({});
    const [backgroundImage, setBackgroundImage] = useState("");

    useEffect(() => {
        const backgrounds = [];
        for (let i = 1; i <= 12; i++) {
            backgrounds.push(`/images/backgrounds/bg-${i}.jpeg`);
        }

        const randomIndex = Math.floor(Math.random() * backgrounds.length);
        const randomBackground = backgrounds[randomIndex];
        setBackgroundImage(randomBackground);

        // Preloading images
        for (const bg of backgrounds) {
            const img = new Image();
            img.src = bg;
        }
    }, []);

    const backgroundStyle = {
        content: '""',
        position: "fixed",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        backgroundImage: `url(${backgroundImage})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundAttachment: "fixed",
        zIndex: -2,
    };

    return (
        <>
            <Toast visible={showToast} {...toastOptions} />

            <div className="background-wrapper" style={backgroundStyle}>
                <div className="background-overlay"></div>

                <Router>
                    <Header pages={pages} />
                    <div className="content-wrapper">
                        <main className="WordcraftStudios-main">
                            <Routes>
                                {Object.entries(pages).map(([path, { component }]) => (
                                    <Route
                                        key={path}
                                        path={path}
                                        element={React.createElement(component, { setShowToast, setToastOptions })}
                                    />
                                ))}
                                <Route path="*" element={<div>404 - Page Not Found</div>} />
                            </Routes>
                        </main>
                    </div>
                </Router>
            </div>
        </>
    );
}

export default App;
